import {Stage} from "../constants/constants";
import {getStage} from "../utils/NetworkUtil";

export const getAmplifyConfig = () => {
    switch (getStage()) {
        case Stage.BETA:
            return AMPLIFY_CONFIG.BETA;
        case Stage.PROD:
            return AMPLIFY_CONFIG.PROD
        // no default
    }

};


export const getAWSConfig = () => {
    switch (getStage()) {
        case Stage.BETA:
            return AWS_CONFIG.BETA;
        case Stage.PROD:
            return AWS_CONFIG.PROD
        // no default
    }
};

/*TODO: sign up a different user for dolphin in cognito pool to fetch credentials*/
export const AWS_CONFIG = {
    BETA: {
        username: 'muparthtest',
        password: 'password',
        identityPoolId: 'us-west-2:b3592c2b-67e8-4a20-b5f4-88fc3d821946',
        region: 'us-west-2',
        userPoolId: 'us-west-2_VNiVY8y1i',
        userPoolWebClientId: '6tbut38ia595a157113eue40o9',
    },
    PROD: {
        username: 'muparthtest',
        password: 'password',
        identityPoolId: 'us-east-1:17bed444-ae67-4b91-a561-f14889175bc1',
        region: 'us-east-1',
        userPoolId: 'us-east-1_d8HXs1eit',
        userPoolWebClientId: '6f8obpg80dplu70pj1q7obi631',
    }
}

export const AMPLIFY_CONFIG = {
    BETA: {
        Auth: {
            identityPoolId: 'us-west-2:b3592c2b-67e8-4a20-b5f4-88fc3d821946',
            region: 'us-west-2',
            userPoolId: 'us-west-2_VNiVY8y1i',
            userPoolWebClientId: '6tbut38ia595a157113eue40o9',
        },
        API: {
            endpoints: [
                {
                    name: "BSMSApi",
                    endpoint: "https://ogah4ohdqe.execute-api.us-west-2.amazonaws.com/beta"
                },
            ]
        }
    },

    PROD: {
        Auth: {
            identityPoolId: 'us-east-1:17bed444-ae67-4b91-a561-f14889175bc1',
            region: 'us-east-1',
            userPoolId: 'us-east-1_d8HXs1eit',
            userPoolWebClientId: '6f8obpg80dplu70pj1q7obi631',
        },
        API: {
            endpoints: [
                {
                    name: "BSMSApi",
                    endpoint: "https://hhi2imzlle.execute-api.us-east-1.amazonaws.com/prod"
                },
            ]
        }
    }
}
