import {getBSMSClient} from "../network/ClientInterface";

export async function getBeaconsForSite(dispatcher, addressId) {

    const BSMSClient = getBSMSClient()
    const response = await BSMSClient.getBeaconsForSite(addressId)
    if (response.status === 200) {
        const siteBeaconsMap = extractBeaconDetails(response)
        dispatcher({
            type: 'RECEIVE_DATA',
            data: siteBeaconsMap
        })
    } else {
        dispatcher({
            type: 'RECEIVE_DATA_ERROR'
        })
    }
}

export const extractBeaconDetails = (response) => {

    const beaconDetailsMap = {}

    if ("data" in response && "beaconInfoList" in response.data) {
        response.data.beaconInfoList.forEach((beaconDetail) => {
            const beaconLocation = beaconDetail.location
            beaconDetailsMap[beaconLocation] = beaconDetail
            beaconDetailsMap[beaconLocation].beaconStateInfo = null
        })
    }
    console.log(beaconDetailsMap)
    return beaconDetailsMap
}
