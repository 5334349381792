import {getBeaconsForSite} from "../../../apis/getBeaconsForSiteAPI";
import {getAllSiteInfo} from "../../../apis/getAllSiteInfoAPI";
import {
    getAllSitesData,
    getBeaconConfigsData,
    storeAllSitesData,
    storeBeaconConfigsData, updateBeaconConfigs
} from "../../../BeaconExecutionDataStore";
import {
    ALL_SITE_DATA_ERROR,
    LOAD_ALL_SITE_DATA, LOAD_VIEW,
    RECEIVE_ALL_SITE_DATA,
    RECEIVE_DATA, RECEIVE_DATA_ERROR,
    REMOVE_BEACON,
    SET_CURRENT_BEACON, UPDATE_BEACONS
} from "../actions/BeaconListActions";

export const BeaconListReducer = (state, action) => {
    switch (action.type) {
        case RECEIVE_ALL_SITE_DATA:
            storeAllSitesData(action.data)
            return {
                ...state,
                allSitesInfoMap: action.data.allSiteData,
                currentSiteConfig: action.data.currentSiteData,
            }
        case ALL_SITE_DATA_ERROR:
            return {
                ...state,
                allSitesInfoMap: {},
                currentSiteConfig: {},
            }

        case LOAD_ALL_SITE_DATA:
            const allSitesResponse = getAllSitesData()
            if (allSitesResponse === null) {
                getAllSiteInfo(action.data.dispatcher)
                return state
            } else {
                return {
                    ...state,
                    allSitesInfoMap: allSitesResponse.allSiteData,
                    currentSiteConfig: allSitesResponse.currentSiteData,
                }
            }

        case RECEIVE_DATA:
            storeBeaconConfigsData(action.data)
            return {
                ...state,
                beaconDeviceInformation: action.data,
                loadingOverview: false
            }

        case RECEIVE_DATA_ERROR:
            return {
                ...state,
                beaconDeviceInformation: {},
                loadingOverview: false
            }

        case LOAD_VIEW:
            const beaconConfigs = getBeaconConfigsData()
            if (beaconConfigs === null) {
                getBeaconsForSite(action.data.dispatcher, action.data.addressId)
                return state
            } else {
                return {
                    ...state,
                    beaconDeviceInformation: beaconConfigs,
                    loadingOverview: true
                }
            }

        case UPDATE_BEACONS:
            updateBeaconConfigs(action.data.updatedBeaconsList)
            return {...state, currentBeacon: action.data.updatedBeacon}

        case SET_CURRENT_BEACON:
            return {...state, currentBeacon: action.data}

        case REMOVE_BEACON:
            updateBeaconConfigs(action.data.updatedBeaconsList)
            return {...state, deletedBeaconConfigs: action.data.deletedBeaconConfigs}

        default:
            return state
    }
}
