import React from "react";
import Column from "@amzn/meridian/column";
import Text from "@amzn/meridian/text";
import {BUILDING_ENTRANCE,
    BUILDING_EXIT,
    INSIDE_LOADING_ZONE,
    OUTSIDE_WAITING_ZONE,
    OUTSIDE_LOADING_ZONE,
    OUTSIDE_LOAD_ZONE_ENTRANCE,
    OUTSIDE_LOAD_ZONE_EXIT
} from "../../constants/BeaconTypes";
import {BUILDING_ENTRANCE_SOP,
    BUILDING_EXIT_SOP,
    INSIDE_LOADING_ZONE_SOP,
    OUTSIDE_WAITING_ZONE_SOP,
    OUTSIDE_LOADING_ZONE_SOP,
    OUTSIDE_LOAD_ZONE_ENTRANCE_SOP,
    OUTSIDE_LOAD_ZONE_EXIT_SOP
} from "../../constants/BeaconSOP";


const BeaconTypeSOP = (props) => {
    const beaconType = props.type;
    let beaconTypeSOP = [];
    let instruction = "When mounting beacon ensure:";
    switch (beaconType) {
        case BUILDING_ENTRANCE:
            beaconTypeSOP = BUILDING_ENTRANCE_SOP
            break;
        case BUILDING_EXIT:
            beaconTypeSOP = BUILDING_EXIT_SOP
            break;
        case INSIDE_LOADING_ZONE:
            beaconTypeSOP = INSIDE_LOADING_ZONE_SOP
            break;
        case OUTSIDE_WAITING_ZONE:
            beaconTypeSOP = OUTSIDE_WAITING_ZONE_SOP
            break;
        case OUTSIDE_LOADING_ZONE:
            beaconTypeSOP = OUTSIDE_LOADING_ZONE_SOP
            instruction = "Only if site has outside loading install an Outside Loading Zone beacon. When mounting beacon ensure:";
            break;
        case OUTSIDE_LOAD_ZONE_ENTRANCE:
            beaconTypeSOP = OUTSIDE_LOAD_ZONE_ENTRANCE_SOP
            instruction = "Only if site has a permanent physical barrier around Loading Zone with a clear entrance threshold, install an Outside Load Zone Entrance beacon. When mounting beacon ensure:"
            break;
        case OUTSIDE_LOAD_ZONE_EXIT:
            beaconTypeSOP = OUTSIDE_LOAD_ZONE_EXIT_SOP
            instruction = "Only if site has a permanent physical barrier around Loading Zone with a clear exit, install an Outside Load Zone Exit beacon. When mounting beacon ensure:"
            break;
        default:
            beaconTypeSOP = []
            instruction = ""
    }
    return (
        <Column spacing="200">
            <Text type="b400" color="primary">
                {beaconType}
            </Text>
            {/*display instruction only when beacon type is selected*/}
            {beaconType === null ?
                <Text>No beacon type selected</Text> :
                <Text type = "b200" color = "primary">
                    {instruction}
                </Text>}
            <Column spacing = "200" spacingInset = "300">
                {beaconTypeSOP.map((sop, index) => (
                    <Text key = {index} tag = "ul" type ="b200" color = "primary">
                        <li>{sop}</li>
                    </Text>
                ))}
            </Column>
        </Column>
    );
}

export default BeaconTypeSOP;
