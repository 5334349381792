import {Stage} from "../constants/constants";

export function getStage() {
    let hostname = window.location.hostname.split('.');
    if (hostname[1] != null) {
        const stage = hostname[1];
        if (stage.endsWith(Stage.BETA)) return Stage.BETA;
        else if (stage.endsWith(Stage.PROD)) return Stage.PROD;
    }
    return Stage.BETA;
}
