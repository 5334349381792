export const BUILDING_ENTRANCE_SOP = [
    "Beacon has unobstructed line of sight to the drive area",
    "Mount 7ft high",
    "Mount on the driver's side of entrance, if possible",
    "Mount 10ft inside the station, if possible",
    "Mount on surface other than metal, if possible"
];

export const BUILDING_EXIT_SOP = [
    "Beacon has unobstructed line of sight to the drive area",
    "Mount 7ft high",
    "Mount on the driver's side of exit, if possible",
    "Mount 10ft inside the station, if possible",
    "Mount on surface other than metal, if possible"
];

export const INSIDE_LOADING_ZONE_SOP = [
    "Mount 7-10ft high, highest reachable point given onsite stepstool",
    "Inside Loading Zone Beacon should cover a radius of 65ft (20m)",
    "Mount near the drive lane, near the central part of the building, away from entry/exit doors",
    "Mount on surface other than metal, if possible"
];

export const OUTSIDE_WAITING_ZONE_SOP = [
    "Mount 7-10ft high, highest reachable point given onsite stepstool",
    "Outside Waiting Zone Beacon should cover a radius of 165ft (50m)",
    "Mount on surface other than metal, if possible"
];

export const OUTSIDE_LOADING_ZONE_SOP = [
    "Mount 7-10ft high, highest reachable point given onsite stepstool",
    "Outside Loading Zone Beacon should cover a radius of 65ft (20m)",
    "Mount on surface other than metal, if possible"
];

export const OUTSIDE_LOAD_ZONE_ENTRANCE_SOP = [
    "Beacon has unobstructed line of sight to the drive area",
    "Mount 7ft high, if possible",
    "Mount on the driver's side of entrance, if possible",
    "Mount at threshold",
    "Mount on surface other than metal, if possible"
];

export const OUTSIDE_LOAD_ZONE_EXIT_SOP = [
    "Beacon has unobstructed line of sight to the drive area",
    "Mount 7ft high, if possible",
    "Mount on the driver's side of exit, if possible",
    "Mount at threshold",
    "Mount on surface other than metal, if possible"
];
