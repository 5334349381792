import React, {useContext, useEffect, useState} from "react";
import Column from "@amzn/meridian/column";
import {AppNavigationContext} from "../AppNavigation/context/AppNavigationContext";
import {TaskView} from "@amzn/dolphin-uiweb-framework";
import {injectIntl} from "react-intl";
import {Routes} from "../../utils/Routes";
import {BeaconListContext} from "../Beacons/context/BeaconListContext";
import Alert from "../common/Alert";
import LoaderView from "../common/LoaderView";

const HomeView = (props) => {
    const {navActions} = useContext(AppNavigationContext);
    const {viewModel, dispatch} = useContext(BeaconListContext);

    // state variables
    const [showAlert, setShowAlert] = useState(false)

    const onManageBeacons = () => {

        const allSiteData = viewModel.allSitesInfoMap
        const currentSiteConfig = viewModel.currentSiteConfig
        if (Object.values(allSiteData).length === 0 ||
            Object.values(currentSiteConfig).length === 0) {
            setShowAlert(true)
        } else {
            setShowAlert(false)
            props.history.push(Routes.MANAGE_BEACONS);
        }
    }

    const onTestBeacon = () => {
        //TODO: handle test beacon
        console.log("test beacons");
    }

    const tasks = [{
        title: props.intl.formatMessage({id:"home.manage_beacons"}),
        description: props.intl.formatMessage({id:"home.add_and_manage_beacons_for_site"}),
        onClick: onManageBeacons
    }, {
        title: props.intl.formatMessage({id:"home.test_beacon"}),
        description: props.intl.formatMessage({id:"home.test_beacon"}),
        onClick: onTestBeacon
    }];

    const getAllSiteData = () => {
        dispatch({type: 'LOAD_ALL_SITE_DATA', data: {dispatcher: dispatch}})
    }

    useEffect(() => {
        getAllSiteData()
        navActions.resetNavCol();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    if (Object.values(viewModel.currentSiteConfig).length === 0) {
        return (<LoaderView/>);
    } else {
        return (
            <Column spacing = "none">
                <Column spacingInset = {'small'}>
                    {showAlert ? <Alert type={'error'}
                                        message={props.intl.formatMessage({id:"home.alert_message"})}/>: <Column/>}
                </Column>
                <TaskView pageTitle={props.intl.formatMessage({id:"home.select_task"})} tasks={tasks}/>
            </Column>
        );
    }
}

export default injectIntl(HomeView);


