import detectionResponse from "../__mocks__/beaconDetectResponse.json"
import calibrationResponse from "../__mocks__/calibrateBeaconResponse.json"
class NativeMeshMock {

    static async detectBeacon() {
        console.log('Request: detectBeacon')
        return new Promise((resolve) => {
            setTimeout(() => {
                resolve({data: detectionResponse})
            }, 2000)
        })
    }

    static async calibrateBeacon() {
        console.log('Request: calibrateBeacon')
        return new Promise((resolve) => {
            setTimeout(() => {
                resolve({data: calibrationResponse})
            }, 3000)
        })
    }
}

export default NativeMeshMock
