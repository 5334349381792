import React, {Component} from 'react';
import './App.css';
import {Routes} from "../utils/Routes";
import {Route, Switch, withRouter} from "react-router-dom";
import AppNavigationContextProvider from "./AppNavigation/context/AppNavigationContext";
import AppNavigation from "./AppNavigation/AppNavigation";
import '@amzn/dolphin-uiweb-framework/dist/index.css'
import Column from "@amzn/meridian/column"
import Loader from "@amzn/meridian/loader";
import BeaconListContextProvider from "./Beacons/context/BeaconListContext";
import HomeView from "./home/HomeView";
import BeaconList from "./Beacons/BeaconList";
import AddBeaconPage from "./AddBeacon/AddBeaconPage";
import BeaconDetectionPage from "./BeaconDetection/BeaconDetectionPage";

function publishRoutePath(location) {
    const metricName = location.pathname;
    console.log(metricName);
}

class App extends Component {

    constructor(props) {
        super(props);
        this.state = {loadFeatures: true};
    }

    async componentWillMount() {
        this.onRouteSwitch = this.props.history.listen((location => {
            publishRoutePath(this.props.location);
        }));
    }

    componentWillUnmount() {
        this.onRouteSwitch();
    }

    render() {
        return (
            <Switch>
                <AppNavigationContextProvider history = {this.props.history} >
                    <Column height="100%" spacing="none">
                        <AppNavigation/>
                        <div className="content">
                            <React.Suspense fallback={<Loader/>}>
                                <Switch>
                                    <Route exact path={Routes.HOME} component={HomeView} />
                                </Switch>
                                <BeaconListContextProvider>
                                    <Switch>
                                        <Route exact path={Routes.MANAGE_BEACONS} component={BeaconList} />
                                    </Switch>
                                    <Switch>
                                        <Route exact path={Routes.ADD_BEACON} component={AddBeaconPage}/>
                                    </Switch>
                                    <Switch>
                                        <Route exact path={Routes.DETECT_BEACON} component={BeaconDetectionPage}/>
                                    </Switch>
                                </BeaconListContextProvider>
                            </React.Suspense>
                        </div>
                    </Column>
                </AppNavigationContextProvider>
            </Switch>
        );
    }
}

export default withRouter(App);
