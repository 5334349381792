import {Auth} from "aws-amplify";
import AWS from "aws-sdk";

export async function signIn(username, password) {
    try {
        return await Auth.signIn(username, password)
    } catch (error) {
        console.log('Error signing in', error);
    }
}

export async function setUserCredentials(username, password, region, identityPoolId, userPoolId) {

    const cognitoUser = await signIn(username, password)
    if (cognitoUser != null) {
        cognitoUser.getSession(function(err, result) {
            if (result) {
                let loginsMap = {}
                const loginsMapKey = 'cognito-idp.' + region + '.amazonaws.com/' + userPoolId
                loginsMap[loginsMapKey] = result.getIdToken().getJwtToken()

                // Add the User's Id Token to the Cognito credentials login map.
                AWS.config.region = region
                AWS.config.credentials = new AWS.CognitoIdentityCredentials({
                    IdentityPoolId: identityPoolId,
                    Logins: loginsMap
                });
            }
        });
    }
}
