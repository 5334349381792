import React, {useState, useEffect, useContext} from "react"
import {FormattedMessage} from "react-intl";
import {injectIntl} from "react-intl";
import Row from "@amzn/meridian/row";
import Button from "@amzn/meridian/button";
import Column from "@amzn/meridian/column";
import Input from "@amzn/meridian/input";
import Text from "@amzn/meridian/text"
import Select, {SelectOption} from "@amzn/meridian/select";
import {BeaconTypes} from "../../constants/BeaconTypes";
import BeaconTypeSOP from "./BeaconTypeSOP";
import {HeaderView} from "@amzn/dolphin-uiweb-framework";
import {AppNavigationContext} from "../AppNavigation/context/AppNavigationContext";
import {Routes} from "../../utils/Routes";
import {isValidName, isValidType} from "../../utils/validations";
import {BeaconListContext} from "../Beacons/context/BeaconListContext";
import {createBeaconConfigObject} from "../../utils/Util";
import Alert from "../common/Alert";
import {BEACON_STATE_INFO, BEACON_TYPE, LOCATION} from "../../constants/BeaconConfigStrings";
import {ADDED} from "../../constants/constants";

const AddBeaconPage = (props) => {

    const {navActions} = useContext(AppNavigationContext);
    const {viewModel, dispatch} = useContext(BeaconListContext)

    // States
    const [type, setType] = useState("");
    const [name, setName] = useState("");
    const [incorrectInputAlert, setIncorrectInputAlert] = useState(false)
    const [alreadyExists, setAlreadyExists] = useState(false)

    // Error
    const [nameErr, setNameErr] = useState(false);
    const [typeErr, setTypeErr] = useState(false);

    const stateValues = {
        name: name,
        type: type,
    }

    // create new empty beacon config object
    const newBeaconConfig = createBeaconConfigObject()

    const onClickRegister = () => {

        setNameErr(!isValidName(stateValues.name));
        setTypeErr(!isValidType(stateValues.type));

        if (!isValidName(stateValues.name) || !isValidType(stateValues.type)) {
            setAlreadyExists(false)
            setIncorrectInputAlert(true);
        } else {
            if (Object.keys(viewModel.beaconDeviceInformation).includes(stateValues.name.toString())) {
                setIncorrectInputAlert(false)
                setAlreadyExists(true)
            }
            else {
                setIncorrectInputAlert(false);
                setAlreadyExists(false)
                addBeacon()
            }
        }
    }

    const addBeacon = () => {
        console.log("Add beacon with name: " + stateValues.name + " and type: " + stateValues.type)
        newBeaconConfig[LOCATION] = stateValues.name
        newBeaconConfig[BEACON_TYPE] = stateValues.type
        newBeaconConfig[BEACON_STATE_INFO] = ADDED

        const beaconConfigs = viewModel.beaconDeviceInformation
        beaconConfigs[newBeaconConfig[LOCATION]] = newBeaconConfig

        dispatch({
            type: 'UPDATE_BEACONS',
            data: {
                updatedBeacon: newBeaconConfig,
                updatedBeaconsList: beaconConfigs
            }
        })
        props.history.push(Routes.DETECT_BEACON);
    }

    const onClickCancel = () => {
        resetInputState();
        setIncorrectInputAlert(false)
        setAlreadyExists(false)
        props.history.goBack()
    }

    function resetInputState() {
        resetInputDetails();
        resetInputErrors();
    }

    const resetInputDetails = () => {
        setName("")
        setType("")
    }

    const resetInputErrors = () => {
        setNameErr(false)
        setTypeErr(false)
    }

    useEffect(() => {
        navActions.resetNavCol();
        navActions.onBackDefault();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const headerData = {
        primaryTitle : {
            title: props.intl.formatMessage({id:"beacon.add_beacon_page"}),
            type: "Bold",
            size: "Large"
        },
        secondaryTitle: {
            title: "",
        }
    }

    return (
        <Column
            width="100%">
            <Column spacing={"medium"} spacingInset={"small"}>
                <HeaderView {...headerData}/>
                <Column spacing="200">
                    <Column>
                        {incorrectInputAlert && <Alert
                            type={'error'}
                            message={props.intl.formatMessage({id:"alert.missingfields"})}/>}
                    </Column>
                    <Column>
                        {alreadyExists && <Alert
                            type={'error'}
                            message={props.intl.formatMessage({id:"alert.beacon_already_exists"})}/>}
                    </Column>
                    <Text tag="label" type="b400" color="primary" htmlFor="name">
                        <FormattedMessage id = "beacon.name" defaultMessage = "Name"/>
                    </Text>
                    <Input id="name"
                           value={name}
                           onChange={setName}
                           type = "text"
                           placeholder = "Enter Beacon Name..."
                           error = {nameErr}
                           autoFill = {false}
                           constraintText = {props.intl.formatMessage({id:"add_beacon.name_constraint_message"})}/>
                </Column>
                <Column spacing="200" >
                    <Text tag="label" type="b400" color="primary" htmlFor="type">
                        <FormattedMessage id = "beacon.type" defaultMessage = "Type"/>
                    </Text>
                    <Select id="type"
                            value={type}
                            onChange={setType}
                            error = {typeErr}
                            constraintText = {props.intl.formatMessage({id:"add_beacon.type_constraint_message"})}
                            placeholder="Select Beacon Type...">
                        {BeaconTypes.map((type, index) => (
                            <SelectOption key ={index} label={type} value={type}/>
                        ))}
                    </Select>
                </Column>
            </Column>
            <Column spacing={"medium"} spacingInset={"medium medium medium large"}>
                <BeaconTypeSOP type = {type} />
            </Column>
            <Column spacingInset={"medium"}>
                <Row alignmentHorizontal="center" widths="fill">
                    <Button type="primary"
                            size="large"
                            onClick = {onClickRegister}>
                       <FormattedMessage id="add_beacon.register" defaultMessage = "Register"/>
                    </Button>
                    <Button type="secondary"
                            size="large"
                            onClick = {onClickCancel}>
                        <FormattedMessage id="add_beacon.cancel" defaultMessage = "Cancel"/>
                    </Button>
                </Row>
            </Column>
        </Column>
    );
}

export default injectIntl(AddBeaconPage);
