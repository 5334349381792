import React, {useEffect, useContext, useState} from "react"
import {FormattedMessage} from "react-intl";
import Table, {TableCell, TableRow} from "@amzn/meridian/table";
import {injectIntl} from "react-intl";
import Row from "@amzn/meridian/row";
import Button from "@amzn/meridian/button";
import Column from "@amzn/meridian/column";
import {Routes} from "../../utils/Routes";
import {BeaconListContext} from "./context/BeaconListContext";
import SaveConfigModal from "../common/SaveConfigModal";
import {AppNavigationContext} from "../AppNavigation/context/AppNavigationContext";
import BeaconInfo from "./BeaconInfo";
import {getBeaconStatus} from "../../utils/Util";

const BeaconList = (props) => {

    const {navActions} = useContext(AppNavigationContext);
    const {viewModel, dispatch} = useContext(BeaconListContext);

    const [openSaveConfigModal, setOpenSaveConfigModal] = useState(false)

    // State for determining if a row is clicked
    const [rowClicked, setRowClicked] = useState(false)

    // State for storing beacon type and name for the selected row to pass as props
    const [selectedBeaconName, setSelectedBeaconName] = useState("")
    const [selectedBeaconType, setSelectedBeaconType] = useState("")
    const [selectedBeaconStatus, setSelectedBeaconStatus] = useState("")

    const handleAddBeacon = () => {
        props.history.push(Routes.ADD_BEACON);
    }

    const handleRowClick = (beaconData) => {
        setRowClicked(true)
        setSelectedBeaconName(beaconData.location)
        setSelectedBeaconType(beaconData.beaconType)
        setSelectedBeaconStatus(getBeaconStatus(beaconData))
    }

    const handleRowClose = () => {
        setRowClicked(false)
        setSelectedBeaconName("")
        setSelectedBeaconType("")
        setSelectedBeaconStatus("")
    }

    const getData = () => {
        dispatch({type: 'LOAD_VIEW', data:{dispatcher: dispatch}});
    }

    useEffect(() => {
        getData();
        navActions.onBackDefault()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const BeaconDeviceList = [...viewModel.BeaconDeviceInformation];

    if (BeaconDeviceList === []) {
        return (<p>Beacons not installed yet for this site</p>);
    } else {
        return (
            <div>
            {rowClicked  === true ? <BeaconInfo location = {selectedBeaconName}
                                                type = {selectedBeaconType}
                                                status = {selectedBeaconStatus}
                                                onBack = {() => handleRowClose()}/> :
                    <Row widths="100%"
                         alignmentVertical="top"
                         spacingInset="none none large none">
                        <Column spacingInset="none none large none" >
                            <br/>
                            <Table
                                headerRows={1}
                                spacing="small"
                                showDividers={true}
                                showStripes={true}>
                                <TableRow highlightOnHover={false}>
                                    <TableCell>
                                        <FormattedMessage id="beacon.location" defaultMessage="Location"/>
                                    </TableCell>
                                    <TableCell>
                                        <FormattedMessage id="beacon.type" defaultMessage="Type"/>
                                    </TableCell>
                                    <TableCell>
                                        <FormattedMessage id="beacon.status" defaultMessage="Status"/>
                                    </TableCell>
                                </TableRow>
                                {BeaconDeviceList.map((device, index) => (
                                    <TableRow key = {index}
                                              highlightOnHover={true}
                                              onClick = {()=> handleRowClick(device)}>
                                        {/* beacon location and beacon name mean the same thing*/}
                                        <TableCell>
                                            <b>{device.location}</b>
                                        </TableCell>
                                        <TableCell>
                                            <b>{device.beaconType}</b>
                                        </TableCell>
                                        <TableCell>
                                            {getBeaconStatus(device)}
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </Table>
                            <div>
                                <Column alignmentVertical="bottom">
                                    <Column spacingInset="small small none">
                                        <Button onClick={handleAddBeacon} type="primary" alignmentHorizontal="center" size= "large">
                                            Add Beacon
                                        </Button>
                                    </Column>
                                    <Column spacingInset="small small none">
                                        <Button onClick={()=> setOpenSaveConfigModal(true)}
                                                type="primary" alignmentHorizontal="center" size= "large">
                                            Save Configuration
                                        </Button>
                                        <SaveConfigModal open = {openSaveConfigModal}
                                                         setOpen = {setOpenSaveConfigModal}>
                                        </SaveConfigModal>
                                    </Column>
                                </Column>
                            </div>
                        </Column>
                    </Row>
            }
            </div>

        );
    }
}



export default injectIntl(BeaconList);

