export const SITE_LIST_NAME = 'briefSiteManagementDataList';
export const TIME_OUT_IN_MS = 50 * 1000;


export const EMPTY_STRING = "";
export const TIMEOUT_STRING = "timeout";

// save config messages
export const SAVE_CONFIG_DESCRIPTION = "Please make sure you are finished configuring all beacons and that all information shown is correct. By clicking on\n" +
    "'AGREE AND SAVE', you assure that the beacon configuration has been performed according to the SOP"
export const UNREGISTERED_BEACONS_MESSAGE = "Some beacons are in 'Unregistered' status. Please complete registration before saving configuration."
export const INCOMPLETE_INSTALLATION_MESSAGE = "Not all the beacons are in 'ReadyToSave' or 'Saved' status." +
    " If you are doing installation, please make sure all the beacons are in 'ReadyToSave' status before saving."

// bluetooth detection messages
export const DETECTION_DESCRIPTION = "Please place the beacon on or very close to the phone, then click on the 'Start Detection' Button.";
export const DETECTION_SUCCESS_MESSAGE = "Beacon detection is complete. Please click on 'Calibrate' to continue"


// Beacon Status constants
export const UNREGISTERED = "Unregistered";
export const UNCALIBRATED = "Uncalibrated";
export const SAVED = "Saved";
export const READY_TO_SAVE = "Ready to Save";

// Site state constants
export const STATE_NOT_STARTED = 'NOT_STARTED'
export const STATE_READY_TO_INSTALL = 'READY_TO_INSTALL'
export const STATE_BEACON_INSTALLED = 'BEACON_INSTALLED'
export const STATE_COMPLETE = 'COMPLETE'
export const STATE_TERMINATED = 'TERMINATED'

// Beacon modification state constants
export const ADDED = "Added"
export const MODIFIED = "modified"
export const DELETED = "deleted"

// calibration constants
export const EXIT_DETECTION_MESSAGE= 'exit'
export const RSSI_NEAR_THRESHOLD = -70
export const UPPER_THRESHOLD_LIMIT = -60
export const LOWER_THRESHOLD_LIMIT = -100
export const THRESHOLD_IN_OUT_DIFF = 10
export const PERCENTILE_RELAXED = 0.8
export const PERCENTILE_AVERAGE = 0.5
export const PERCENTILE_DEFAULT = 0.8
export const MAX_VALID_THRESHOLD = -60;
export const MIN_VALID_THRESHOLD = -100;

// validation constants
export const MOVING_AVERAGE_NUM_SAMPLE = 5
export const DEFAULT_VALIDATION_DURATION = 10;
export const DEFAULT_EXPECTED_NUM_SAMPLE_IN_SECOND = 1;
export const TOLERANCE = 0.5;

export const RESULT_CODES = {
    SUCCESS: "SUCCESS",
    NOT_ENOUGH_DATA: "NOT_ENOUGH_DATA",
    TOO_CLOSE:"TOO_CLOSE",
    TOO_FAR: "TOO_FAR",
    ERROR:"ERROR"
}

export const Stage = {
    BETA: 'beta',
    PROD: 'prod'
};



