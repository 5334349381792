import {AppNavigationActions} from "../actions/AppNavigationActions";

export const AppNavigationReducer = (state, action) => {

    switch (action.type) {
        case AppNavigationActions.SETUP_HELP:
            return {...state, helpEnabled: action.value.enabled, helpOptions: {...state.helpOptions, ...action.value.options}};

        case AppNavigationActions.OPEN_HELP:
            return {...state, helpOptions: {...state.helpOptions, open: true}};

        case AppNavigationActions.CLOSE_HELP:
            return {...state, helpOptions: {...state.helpOptions, open: false}};

        case AppNavigationActions.ERROR_COL:
            return {...state, navType: 'error'};

        case AppNavigationActions.RESET_COL:
            return {...state, navType: 'default'};

        case AppNavigationActions.CALLBACK_ON_BACK:
            return {...state, onBackClicked: action.callback};

        default:
            return state;
    }
}
