import React from "react"
import Modal, { ModalFooter } from "@amzn/meridian/modal"
import Button from "@amzn/meridian/button"
import {FormattedMessage} from "react-intl";
import {injectIntl} from "react-intl";
import Column from "@amzn/meridian/column";

const EditButtonModal = (props) => {

    const {open, setOpen} = props

    const onCloseModal = () => {
        setOpen(false);
    }

    const onClickReplace = () => {
        // TODO: function to replace beacon
    }

    const onClickRecalibrate = () => {
        // TODO: function to recalibrate beacon
    }

    const onClickRename = () => {
        // TODO: function to rename beacon
    }

    const onClickChangeBeaconType = () => {
        // TODO: function to change beacon type
    }

    return (
        <React.Fragment>
            <Modal
                title= {props.intl.formatMessage({id: "edit_beacon.edit_beacon"})}
                open={open}
                onClose={onCloseModal}
                scrollContainer="viewport"
                closeLabel="Close"
                aria-describedby="modal-description">
                <Column>
                    <Button type="primary" size="medium" onClick={onClickReplace}>
                        <FormattedMessage id = "edit_beacon.replace_beacon" defaultMessage = "Replace Beacon"/>
                    </Button>
                    <Button type="primary" size="medium" onClick={onClickRecalibrate}>
                        <FormattedMessage id = "edit_beacon.recalibrate" defaultMessage = "ReCalibrate"/>
                    </Button>
                    <Button type="primary" size="medium" onClick={onClickRename}>
                        <FormattedMessage id = "edit_beacon.rename" defaultMessage = "Rename"/>
                    </Button>
                    <Button type="primary" size="medium" onClick={onClickChangeBeaconType}>
                        <FormattedMessage id = "edit_beacon.change_beacon_type" defaultMessage = "Change Beacon Type"/>
                    </Button>
                </Column>
                <ModalFooter>
                    <Button type="secondary" size="medium" onClick={onCloseModal}>
                        <FormattedMessage id = "add_beacon.cancel" defaultMessage = "Cancel"/>
                    </Button>
                </ModalFooter>
            </Modal>
        </React.Fragment>
    )
}

export default injectIntl(EditButtonModal);
