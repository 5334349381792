import React from 'react'
import Column from "@amzn/meridian/column";
import Loader from "@amzn/meridian/loader"

const LoaderView = () => {
    return (
        <Column alignmentHorizontal="center" spacingInset = {'medium'}>
            <Column alignmentHorizontal="center" spacingInset = {'medium'}>
                <Loader type="circular" size="large"/>
            </Column>
        </Column>
    )
}

export default LoaderView
