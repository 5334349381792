import {
    UNREGISTERED,
    UNCALIBRATED,
    READY_TO_SAVE,
    SAVED
} from "../constants/constants";
import {
    BATTERY,
    BEACON_ID, BEACON_TYPE, BEACON_STATE_INFO,
    BEACON_UUID,
    ENABLED,
    LOCATION,
    MODEL, NO_SIGNAL_LATENCY,
    PROTOCOL,
    RSSI_IN_THRESHOLD, RSSI_OUT_THRESHOLD, STATE_TRAN_LATENCY
} from "../constants/BeaconConfigStrings";

// get status of beacons from beacon configuration
export const getBeaconStatus = (beaconDetails) => {
    let status = 'NO_VALUE';

    if (null === beaconDetails.beaconId ||  null === beaconDetails.beaconUuid
    || beaconDetails.beaconId === "" || beaconDetails.beaconUuid === "") {
        status = UNREGISTERED
    } else if (0 === beaconDetails.rssiInThreshold || beaconDetails.rssiInThreshold === null ||
        0 === beaconDetails.rssiOutThreshold || beaconDetails.rssiOutThreshold === null) {
        status = UNCALIBRATED
    } else if (beaconDetails.beaconStateInfo) {
        status = READY_TO_SAVE
    } else {
        status = SAVED
    }

    return status
}

export function createBeaconConfigObject() {
    let beaconConfigObject = {}
    beaconConfigObject[BEACON_ID]=null
    beaconConfigObject[BEACON_UUID]=null
    beaconConfigObject[PROTOCOL] = "iBeacon"
    beaconConfigObject[MODEL] = "Estimote"
    beaconConfigObject[ENABLED] = true
    beaconConfigObject[BATTERY] = 100
    beaconConfigObject[LOCATION] = null
    beaconConfigObject[RSSI_IN_THRESHOLD] = null
    beaconConfigObject[RSSI_OUT_THRESHOLD] = null
    beaconConfigObject[STATE_TRAN_LATENCY] = 2000
    beaconConfigObject[NO_SIGNAL_LATENCY] = 5000
    beaconConfigObject[BEACON_TYPE] = null
    beaconConfigObject[BEACON_STATE_INFO] = null

    return beaconConfigObject
}
