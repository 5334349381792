import {getBeaconStatus} from "./Util";
import {MAX_VALID_THRESHOLD, MIN_VALID_THRESHOLD, READY_TO_SAVE, SAVED, UNREGISTERED} from "../constants/constants";
import {LOCATION, RSSI_IN_THRESHOLD, RSSI_OUT_THRESHOLD} from "../constants/BeaconConfigStrings";

export const isValidName = (beaconName) => {
    const alphanumRegex = /^[0-9a-zA-Z]+$/
    return !(beaconName.length === 0 || alphanumRegex.test(beaconName) === false);
}

export const isValidType = (beaconType) => {
    return beaconType.length !== 0;
}

// checks if all beacons have completed registration
export const completeRegistration = (BeaconConfigsMap) => {
    const beaconConfigs = Object.values(BeaconConfigsMap)
    let complete = true
    beaconConfigs.forEach((config) => {
        const beaconStatus = getBeaconStatus(config)
        if (beaconStatus === UNREGISTERED) {
            complete = false
        }
    })
    return complete
}

// checks if all beacons have completed installation
export const completeInstallation = (BeaconConfigsMap) => {
    const beaconConfigs = Object.values(BeaconConfigsMap)
    let complete = true
    beaconConfigs.forEach((config) => {
        const beaconStatus = getBeaconStatus(config)
        if (!(beaconStatus === SAVED || beaconStatus === READY_TO_SAVE)) {
            complete = false
            return complete
        }
    })
    console.log(complete)
    return complete
}

// return list of beacons with invalid thresholds
export const allBeaconsThresholdsValid = (beaconConfigsMap) => {
    const beaconConfigs = Object.values(beaconConfigsMap)
    const invalidBeacons = []
    for (let i=0; i< beaconConfigs.length; i++) {

        const beaconInRssi = beaconConfigs[i][RSSI_IN_THRESHOLD]
        const beaconOutRssi = beaconConfigs[i][RSSI_OUT_THRESHOLD]
        const beaconLocation = beaconConfigs[i][LOCATION]

        if (beaconInRssi > MAX_VALID_THRESHOLD || beaconInRssi < MIN_VALID_THRESHOLD ||
            beaconOutRssi > MAX_VALID_THRESHOLD - 10 || beaconOutRssi < MIN_VALID_THRESHOLD - 10) {
            invalidBeacons.push(beaconLocation)
        }
    }
    return invalidBeacons
}
