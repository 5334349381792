import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './components/App';
import "@amzn/meridian-tokens/base/font/amazon-ember.css";
import {IntlProvider} from "react-intl";
import { KatSpinner } from '@amzn/katal-react';
import {getDirection, getLocale, getMessages} from "./components/handler/LocaleHandler";
import { addLocaleData } from 'react-intl';
import en from 'react-intl/locale-data/en'
import ja from 'react-intl/locale-data/ja'
import it from 'react-intl/locale-data/it'
import fr from 'react-intl/locale-data/fr'
import es from 'react-intl/locale-data/es'
import de from 'react-intl/locale-data/de'
import ar from 'react-intl/locale-data/ar'
import {HashRouter} from "react-router-dom";
import { Amplify, API } from 'aws-amplify';
import {setUserCredentials} from "./stores/CurrentUserStore";
import {getAmplifyConfig, getAWSConfig} from "./configs/Configs";

addLocaleData(en)
addLocaleData(ja)
addLocaleData(it)
addLocaleData(de)
addLocaleData(fr)
addLocaleData(es)
addLocaleData(ar)

Amplify.configure(getAmplifyConfig());
API.configure(getAmplifyConfig());
const AWSConfig = getAWSConfig()

// The Suspense component causes React to display the fallback KatSpinner until
// the i18n translation strings have been loaded.
getLocale().then(async locale => {
    await setUserCredentials(AWSConfig.username, AWSConfig.password, AWSConfig.region,
        AWSConfig.identityPoolId, AWSConfig.userPoolId)

    document.getElementsByTagName(`html`)[0].setAttribute("dir", getDirection(locale));
    ReactDOM.render((
        <React.Suspense fallback={<KatSpinner size='large' />}>
            <IntlProvider locale={locale} messages={getMessages(locale)}>
                <HashRouter>
                    <App/>
                </HashRouter>
            </IntlProvider>
        </React.Suspense>
    ), document.getElementById('root'));
})
