import {TIMEOUT_STRING} from "../../constants/constants";

const postMessagePromises = {};

function generateGuid(){
    /**
     * @return {string}
     */
    function S4() {
        return (((1+Math.random())*0x10000)|0).toString(16).substring(1);
    }
    return (S4() + S4() + "-" + S4() + "-4" + S4().substr(0,3) + "-" + S4() + "-" + S4() + S4() + S4()).toLowerCase();
}

function postMessagePromised(targetWindow, message, targetOrigin, timeout) {
    const newGuid = generateGuid();
    message.guid = newGuid;
    postMessagePromises[newGuid] = {};
    postMessagePromises[newGuid]['promise'] = new Promise(function (resolve, reject) {
        postMessagePromises[newGuid]['resolve'] = resolve;
        postMessagePromises[newGuid]['reject'] = reject;
        targetWindow.postMessage(message, targetOrigin);
        if (timeout) {
            setTimeout(() => {
                postMessagePromises[newGuid].resolve(TIMEOUT_STRING);
            },timeout);
        }
    });
    return postMessagePromises[newGuid].promise;
}

export async function getLocaleFromParent() {
    return await postMessagePromised(window.parent, {
        method: 'getLocale',
        args: {}
    }, '*',200);
}
