import {BEACON_SESSION_STORAGE_KEYS} from "./constants/DataStoreKeys";

export function storeAllSitesData(response) {
    sessionStorage.setItem(BEACON_SESSION_STORAGE_KEYS.ALL_SITES_DATA, JSON.stringify(response))
}

export function getAllSitesData() {
    return JSON.parse(sessionStorage.getItem(BEACON_SESSION_STORAGE_KEYS.ALL_SITES_DATA))
}

export function storeBeaconConfigsData(response) {
    sessionStorage.setItem(BEACON_SESSION_STORAGE_KEYS.BEACON_CONFIGS, JSON.stringify(response))
}

export function getBeaconConfigsData() {
    return JSON.parse(sessionStorage.getItem(BEACON_SESSION_STORAGE_KEYS.BEACON_CONFIGS))
}

export function updateBeaconConfigs(updatedBeaconConfigMap) {
    sessionStorage.setItem(BEACON_SESSION_STORAGE_KEYS.BEACON_CONFIGS, JSON.stringify(updatedBeaconConfigMap))
}

export function clearBeaconConfigs() {
    sessionStorage.removeItem(BEACON_SESSION_STORAGE_KEYS.BEACON_CONFIGS)
}
