import React, {createContext, useReducer} from 'react'
import {BeaconListReducer} from "../reducer/BeaconListReducer";

export const BeaconListContext = createContext();

const BeaconListContextProvider = (props) => {
    const data = {
        allSitesInfoMap: {},
        currentSiteConfig: {},
        beaconDeviceInformation: {},
        currentBeacon: {},
        deletedBeaconConfigs: [],
        loadingOverview: true
    }
    const [viewModel, dispatch] = useReducer(BeaconListReducer, data)
    return (
        <BeaconListContext.Provider value = {{viewModel, dispatch}}>
            {props.children}
        </BeaconListContext.Provider>
    )
}

export default BeaconListContextProvider
