let helpCloseInterceptor = false
let prevBackPressedListener = undefined;
let interceptBackButtonReason = undefined;

/**
 * Listener to history events to call appropriate callbacks
 */
window.addEventListener('popstate', function (event) {
    if (helpCloseInterceptor) {
        prevBackPressedListener && prevBackPressedListener(event);
        helpCloseInterceptor = false;
    }
    else if (interceptBackButtonReason) {
        window.history.pushState({noBack: true}, '');
        prevBackPressedListener && prevBackPressedListener(event);
    }
});

export function setBackPressedEventListener(listener) {
    prevBackPressedListener = listener;
}

export function clearBackPressedEventListener() {
    prevBackPressedListener = undefined;
}

/**
 * Help Option close is handled separately from
 * page navigation behaviours
 */
export function interceptHelpClose() {
    helpCloseInterceptor = true;
    window.history.pushState({helpOpen: true}, '');
}

export function interceptBackButton(reason) {
    interceptBackButtonReason = reason;
    window.history.pushState({noBack: true}, '');
}

export function interceptBackButtonStop() {
    interceptBackButtonReason = undefined;
}
