export const BUILDING_ENTRANCE = "Building Entrance";

export const BUILDING_EXIT = "Building Exit";

export const INSIDE_LOADING_ZONE = "Inside Loading Zone";

export const OUTSIDE_WAITING_ZONE = "Outside Waiting Zone";

export const OUTSIDE_LOADING_ZONE = "Outside Loading Zone";

export const OUTSIDE_LOAD_ZONE_ENTRANCE = "Outside Load Zone Entrance";

export const OUTSIDE_LOAD_ZONE_EXIT = "Outside Load Zone Exit";

export const BeaconTypes = [
    BUILDING_ENTRANCE,
    BUILDING_EXIT,
    INSIDE_LOADING_ZONE,
    OUTSIDE_WAITING_ZONE,
    OUTSIDE_LOADING_ZONE,
    OUTSIDE_LOAD_ZONE_ENTRANCE,
    OUTSIDE_LOAD_ZONE_EXIT
]
