import axios from 'axios'
import {TIME_OUT_IN_MS} from "../constants/constants";
import {API, Auth, Signer} from "aws-amplify";

var AWS = require('aws-sdk');

var accessKeyId
var secretAccessKey
var sessionToken

// Credentials will be available when this function is called.
async function getCredentials() {
    await AWS.config.credentials.getPromise()
    accessKeyId = AWS.config.credentials.accessKeyId;
    secretAccessKey = AWS.config.credentials.secretAccessKey;
    sessionToken = AWS.config.credentials.sessionToken;
}

async function getConfig(method, apiName, path, awsAuth, payload) {
    const endpoint = await API.endpoint(apiName);
    let signedRequest;

    await getCredentials()

    if (awsAuth === 'awsv4') {
        signedRequest = Signer.sign({
            method: method,
            url: endpoint + path,
            headers: {},
            data: payload && JSON.stringify(payload)
        }, {
            // eslint-disable-next-line camelcase
            secret_key: secretAccessKey,
            // eslint-disable-next-line camelcase
            access_key: accessKeyId,
            // eslint-disable-next-line camelcase
            session_token: sessionToken
        });
    } else if (awsAuth === 'cognito') {
        signedRequest = {
            headers: {Authorization: `Bearer ${(await Auth.currentSession()).getIdToken().getJwtToken()}`}
        };
    }

    const config = {
        timeout: TIME_OUT_IN_MS
    }
    if (signedRequest) {
        config.headers = signedRequest.headers;
    }

    return config;
}

async function get(apiName, path, awsAuth) {
    const endpoint = await API.endpoint(apiName);
    return await axios.get(endpoint + path, await getConfig('GET', apiName, path, awsAuth));
}

// eslint-disable-next-line
async function post(apiName, path, awsAuth, payload) {
    const endpoint = await API.endpoint(apiName);
    return await axios.post(endpoint + path, payload, await getConfig('POST', apiName, path, awsAuth, payload));
}

// eslint-disable-next-line
async function put(apiName, path, awsAuth, payload) {
    const endpoint = await API.endpoint(apiName);
    return await axios.put(endpoint + path, payload, await getConfig('PUT', apiName, path, awsAuth, payload));
}

class ApiClient {
    static async getSiteList() {
        return await get("BSMSApi", '/bsms/siteinfo', 'awsv4');
    }

    static async getBeaconsForSite(accessPointId) {
        console.log('Request: getBeaconForSite')
        return await get('BSMSApi', `/bmsproxy/getBeaconConfig/${accessPointId}`, 'cognito')
    }

    static async saveConfig(accessPointId, useCaseId, beaconConfigsList) {
        console.log('Request: saveConfigToBackend')
    }
}

export default ApiClient;
