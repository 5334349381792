import React from "react"
import Modal, { ModalFooter } from "@amzn/meridian/modal"
import Row from "@amzn/meridian/row"
import Button from "@amzn/meridian/button"
import Text from "@amzn/meridian/text"
import {SAVE_CONFIG_DESCRIPTION} from "../../constants/constants";
import {FormattedMessage} from "react-intl";
import {injectIntl} from "react-intl";

const SaveConfigModal = (props) => {
    const {open, setOpen} = props

    const onCloseModal = () => {
        setOpen(false);
    }

    const onClickConfirm = () => {
        // TODO: function to save the beacon configuration
    }

    return (
        <React.Fragment>
            <Modal
                title= {props.intl.formatMessage({id: "beacon.save_configuration"})}
                open={open}
                onClose={onCloseModal}
                scrollContainer="viewport"
                closeLabel="Close"
                aria-describedby="modal-description"
            >
                <Text id="modal-description">
                    {SAVE_CONFIG_DESCRIPTION}
                </Text>
                <ModalFooter>
                    <Row alignmentHorizontal="end" widths="fit">
                        <Button type="secondary" size="medium" onClick={onCloseModal}>
                            <FormattedMessage id = "add_beacon.cancel" defaultMessage = "Cancel"/>
                        </Button>
                        <Button type="primary" size="medium" onClick={onClickConfirm}>
                            <FormattedMessage id = "save_config.agree_and_save" defaultMessage = "Agree and Save"/>
                        </Button>
                    </Row>
                </ModalFooter>
            </Modal>
        </React.Fragment>
    )
}

export default injectIntl(SaveConfigModal);
