import React, {useContext, useEffect, useState} from "react";
import Column from "@amzn/meridian/column";
import {HeaderView} from "@amzn/dolphin-uiweb-framework";
import Text from "@amzn/meridian/text";
import {FormattedMessage} from "react-intl";
import BeaconTypeSOP from "../AddBeacon/BeaconTypeSOP";
import {injectIntl} from "react-intl";
import Row from "@amzn/meridian/row";
import {AppNavigationContext} from "../AppNavigation/context/AppNavigationContext";
import Button from "@amzn/meridian/button";
import EditButtonModal from "../common/EditButtonModal";

const BeaconInfo = (props) => {

    const {navActions} = useContext(AppNavigationContext)

    //states
    const [editButtonClicked, setEditButtonClicked] = useState(false);

    const headerData = {
        primaryTitle : {
            title: props.intl.formatMessage({id:"beacon.beacon_info"}),
            type: "Bold",
            size: "Large"
        },
        secondaryTitle: {
            title: "",
        }
    }

    useEffect( () =>{
        navActions.callbackOnBack(props.onBack)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <Column
            width="100%">
            <Column spacing={"medium"} spacingInset={"medium"}>
                <HeaderView {...headerData}/>
                <Column spacing="200">
                    <Row>
                        <Text tag="label" type="b400" color="primary" htmlFor="name">
                            <FormattedMessage id = "beacon_info.name" defaultMessage = "Name"/>
                        </Text>
                        <Text tag="label" type="b400" color="primary" htmlFor="name">
                            {props.location}
                        </Text>
                    </Row>
                </Column>
                <Column spacing="200" >
                    <Row>
                        <Text tag="label" type="b400" color="primary" htmlFor="type">
                            <FormattedMessage id = "beacon_info.type" defaultMessage = "Type"/>
                        </Text>
                        <Text tag="label" type="b400" color="primary" htmlFor="type">
                            {props.type}
                        </Text>
                    </Row>
                </Column>
                <Column spacing="200">
                    <Row>
                        <Text tag="label" type="b400" color="primary" htmlFor="name">
                            <FormattedMessage id = "beacon_info.status" defaultMessage = "Status"/>
                        </Text>
                        <Text tag="label" type="b400" color="primary" htmlFor="status">
                            {props.status}
                        </Text>
                    </Row>
                </Column>
            </Column>
            <Column spacing={"medium"} spacingInset={"medium medium medium large"}>
                <BeaconTypeSOP type = {props.type} />
            </Column>
            <Column alignmentVertical = "bottom">
                <Column spacingInset="small small none">
                    <Button type="primary" alignmentHorizontal="center" size= "large">
                        Validate
                    </Button>
                    <Button type="primary"
                            alignmentHorizontal="center"
                            size= "large"
                            onClick = {() => setEditButtonClicked(true)}>
                        Edit
                    </Button>
                    <Button type="primary" alignmentHorizontal="center" size= "large">
                        Remove
                    </Button>
                    <Button onClick={props.onBack} type="secondary" alignmentHorizontal="center" size= "large">
                        Back
                    </Button>
                    {editButtonClicked  && <EditButtonModal open = {editButtonClicked}
                                                            setOpen = {setEditButtonClicked}>
                    </EditButtonModal>}
                </Column>
            </Column>
        </Column>
    );
}

export default injectIntl(BeaconInfo);
