import {getBSMSClient, getDataHandler} from "../network/ClientInterface";
import {
    STATE_NOT_STARTED,
    STATE_TERMINATED
} from "../constants/constants";

export async function getAllSiteInfo(dispatcher) {
    // fetch details of all sites from BSMS
    const BSMSClient = getBSMSClient()
    const response = await BSMSClient.getSiteList()
    if (response.status === 200) {
        const allSiteInfoMapping = extractAllSiteDetails(response)
        const currentSiteConfig = getCurrentSiteConfig(allSiteInfoMapping)
        dispatcher({
            type: 'RECEIVE_ALL_SITE_DATA',
            data: {
                allSiteData: allSiteInfoMapping,
                currentSiteData: currentSiteConfig
            }
        })
    } else {
        dispatcher({
            type: 'ALL_SITE_DATA_ERROR'
        })
    }
}

// Extract data from api response into a map with site Name as keys
export const extractAllSiteDetails = (response) => {
    const allSiteInfoMap = {}

    if ("data" in response && "briefSiteManagementDataList" in response.data) {
        response.data.briefSiteManagementDataList.forEach((siteDetail) => {
            const siteName = siteDetail.siteCode
            allSiteInfoMap[siteName] = siteDetail
        })
    }
    return allSiteInfoMap
}

export const getCurrentSiteConfig = (allSiteInfoMap) => {
    let currentSiteConfig = {}
    const currentSiteName = getSiteName()
    if (currentSiteName in allSiteInfoMap) {
        if (allSiteInfoMap[currentSiteName].deploymentState !== STATE_NOT_STARTED &&
            allSiteInfoMap[currentSiteName].deploymentState !== STATE_TERMINATED) {
            currentSiteConfig = allSiteInfoMap[currentSiteName]
        } else {
            /* TODO: Evaluate if logs need to be pushed to katal logger */
            console.log('site is not ready for beacon installation')
        }
    } else {
        console.log('SiteCode not present in AllSiteInfo data')
    }
    return currentSiteConfig
}

export const getSiteName = () => {
    const dataHandler = getDataHandler()
    return dataHandler.getSiteCode()
}
