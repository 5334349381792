import apiClient from "../apis/api-client";
import apiClientMock from "../apis/api-client-mock";
import NativeMeshMock from "../mesh/NativeMeshMock";
import DataHandlerMock from "../mesh/DataHandlerMock"
import {DataHandler, NativeMeshInteractor} from "@amzn/dolphin-web-framework";
import {MOCK_NETWORK} from "../dev-debug";


export const getBSMSClient = () => {
    return MOCK_NETWORK ? apiClientMock : apiClient;
}

export const getNativeMeshInteractor = () => {
    return MOCK_NETWORK ? NativeMeshMock : NativeMeshInteractor;
}

export const getDataHandler = () => {
    return MOCK_NETWORK ? DataHandlerMock : DataHandler
}
