import React, {useEffect, useContext} from "react"
import {FormattedMessage} from "react-intl";
import {injectIntl} from "react-intl";
import Row from "@amzn/meridian/row";
import Button from "@amzn/meridian/button";
import Column from "@amzn/meridian/column";
import Text from "@amzn/meridian/text"
import {HeaderView} from "@amzn/dolphin-uiweb-framework";
import {AppNavigationContext} from "../AppNavigation/context/AppNavigationContext";
import {DETECTION_DESCRIPTION} from "../../constants/constants";
import beacon from "./beacon.png"

const BeaconDetectionPage = (props) => {
    const {navActions} = useContext(AppNavigationContext);

    const headerData = {
        primaryTitle : {
            title: props.intl.formatMessage({id:"beacon.beacon_detection_page"}),
            type: "Bold",
            size: "Large"
        },
        secondaryTitle: {
            title: "",
        }
    }


    const onClickStartDetection = () => {
        //TODO: function to register new beacon
    }

    const onClickCancel = () => {
        //TODO: function to cancel beacon registration and redirect to beacon list page
        props.history.goBack()
    }

    useEffect(() => {
        navActions.resetNavCol();
        navActions.onBackDefault();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <Column
            width="100%">
            <Column spacing={"medium"} spacingInset={"medium"}>
                <HeaderView {...headerData}/>
                <Column spacing="200">
                    <Text tag="label" type="b400" color="primary" htmlFor="name">
                        {DETECTION_DESCRIPTION}
                    </Text>
                </Column>
                <Column spacing="200" spacingInset = "600">
                    <img src={beacon} alt= "beacon"
                         style= {{width:"200px", height: "300px", alignSelf: "center"}}/>
                </Column>
            </Column>
            <Column spacingInset={"medium"} alignmentVertical = {"bottom"}>
                <Row alignmentHorizontal="center" widths="fill">
                    <Button type="primary"
                            size="large"
                            onClick = {onClickStartDetection}>
                        <FormattedMessage id="beacon_detection.start_detection" defaultMessage = "Start Detection"/>
                    </Button>
                    <Button type="secondary"
                            size="large"
                            onClick = {onClickCancel}>
                        <FormattedMessage id="add_beacon.cancel" defaultMessage = "Cancel"/>
                    </Button>
                </Row>
            </Column>
        </Column>
    );
}

export default injectIntl(BeaconDetectionPage);
